<template>
    <div>
        <van-nav-bar
            left-arrow
            @click-left="onClickLeft"
            :title="titleName"/>
    <van-form   @submit="submit"> 
        
        <uploader-image :bigTitle="toTitle(index)" :smellTitle="smellTitle" 
                 :uploaderImage="loderImage" :isnum='toNum(index)'
                 v-for="(item,index) in uploaderArr" :key="index"  :item=item
                 @deleteIndex="deleteEduction" :is-power="isPower" :is-water="isWater"
                 >
        </uploader-image>
            
        
        <div class="addDiv" @click="toClickAdd" v-show="isShowSubmit">
            <img src="../../assets/image/powerMeasure/add.png" alt="">
            <p>增加票据</p>
        </div>
        <button class="save" type="submit" v-show="isShowSubmit">保存</button>
    </van-form>
    </div>
</template>
<script>
 import UploaderImage from '../../components/uploaderImage/UploaderImage.vue'
 import {getEnclosure,saveEnclosure} from '../../network/addMes'
    export default{
        name:'UpLoderList',
        data(){
            return{
                titleName:'上传附件',
                bigTitle:'',
                smellTitle:'',
                loderImage:'',
                uploadType:'',
                allTotalCost:'',//用电费用
                isPower:false,
                isWater:false,
                uploaderArr:[],
                isShowSubmit:true,//是否有保存按钮
            } 
        },
        components: { UploaderImage },
        methods:{
            toNum(index){
                return Number(index)+1
            },
            toTitle(index){
                 let num = Number(index) +1;
                return this.bigTitle+ num;
            },
            onClickLeft(){
                this.$router.back();
            },
            submit(values){  
                     values['assessId'] = this.$route.query.id;
                     values['type'] = this.uploadType;
                     for (const iterator in values) {
                        if(iterator.includes('uploadInvoice')){
                            if( values[iterator].constructor == Array){
                                if(values[iterator].length!=0){
                                    if( values[iterator][0].url){
                                        values[iterator] = values[iterator][0].url; 
                                    }else{
                                        values[iterator] = values[iterator].toString();
                                    }
                                }else{
                                    values[iterator] = values[iterator].toString();
                                }         
                            }
                            
                        }
                     }
                 this.$toast.loading({
                        message: '努力提交中...',
                        forbidClick: false,
                        duration:0
                     });
                saveEnclosure(values).then((res)=>{
                    this.$toast.clear();
                    if(res.success){
                        
                        this.$toast.success(res.msg);
                        this.$router.back();
                    }else{
                        this.$toast.fail(res.msg);
                    }
                })
            },
            toClickAdd(){
                this.uploaderArr.push({});
            },
            deleteEduction(index){
                index --;
                console.log('shanchu1',index);
                 this.uploaderArr.splice(index,1);
            }
        },
        beforeMount(){
            let type = this.$route.query.type;
            let id = this.$route.query.id;
            this.titleName = this.titleName +'-'+ type;
            this.smellTitle = type+'月份';
            this.bigTitle = type;
            switch (type) {
                case '发票':
                    this.loderImage = 'powerImage.png'
                    this.uploadType = 1
                    this.isPower = true
                    break;
                case '水单表':
                    this.loderImage = 'waterImage.png'
                    this.uploadType = 3
                    this.isWater = true
                    break;
                case '核稽表':
                    this.loderImage = 'checkImage.png'
                    this.uploadType = 2
                    break;
                default:
                    break;
            }
            if(id){
                getEnclosure(id,this.uploadType,sessionStorage.getItem('userId')).then((res)=>{
                    if(res.success){
                        if(res.data.length == 0){
                            this.uploaderArr
                        }else{
                          //  this.uploaderArr=Object.assign({}, )
                          res.data.forEach(element => {
                            if(element.uploadInvoice.length != 0){
                                element.uploadInvoice = 'admin'+element.uploadInvoice
                            }
                            
                            this.uploaderArr.push(element)
                          });    
                        }
                    }
                }) 
                 
                let identity = sessionStorage.getItem('identity');
                if(identity && identity!= 'customer'){
                        this.isShowSubmit = false
                }
            }
        }, 
       
    }
</script>
<style scoped>
.save{
    width: 88%;
    margin: 58px auto ;
    background: #01A4A4;
    border-radius: 40px;
    line-height: 80px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    display: block;
    color: #FFFFFF;
}
.contactDiv{
    width: 100%;
    background: #EEF0FC;
    line-height: 50px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    padding-left: 6%;
}

.addDiv{
    border-top: solid 1px #EEF0FC;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}
.addDiv img{
    width: 41px;
    height: 41px;
    margin-right: 12px;
}
.addDiv p{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #01A4A4;
    line-height: 130px;
   
}
</style>
